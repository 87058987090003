import React, { useCallback, useContext, useEffect, useState } from "react";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./AzureAuthConfig";
import { InteractionType } from "@azure/msal-browser";
import LoadingView from "../ui/LoadingView";

export type AuthInfo = {
  firstName?: string;
  lastName?: string;
  eid?: string;
  groupBranch?: string;
  graphAccessToken?: string;
  role?: string;
};

export type AuthInfoContext = {
  authInfo?: AuthInfo;
  logout: () => void;
};

export type AuthParams = {
  audience: string;
  ehiCallingApplication: string;
  jwtLocationKey?: string;
};

const AzureSSOContext = React.createContext<AuthInfoContext | undefined>(
  undefined
);

const authRequest = {
  ...loginRequest,
};

export const useAzureSSO = (): AuthInfoContext => {
  const authInfo = useContext(AzureSSOContext);
  if (authInfo === undefined) {
    throw new Error("SSO Auth is not configured yet.");
  }
  return authInfo;
};

function ErrorComponent() {
  console.log(window.location.protocol);
  if (
    window.location.protocol === "http:" &&
    window.location.hostname !== "localhost"
  ) {
    window.location.assign(window.location.href.replace("http:", "https:"));
  }
  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      There was an error signing into Active Directory.
    </div>
  );
}

function LoadingComponent() {
  return <LoadingView message="Signing into AD" />;
}

interface Props {
  children: React.ReactNode;
}

export const AzureSSOProvider: React.FC<Props> = (props) => {
  const { instance, accounts } = useMsal();
  const [authInfo, setAuthInfo] = useState<AuthInfo | undefined>(undefined);

  const callMsGraph = useCallback(async (accessToken: string) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(
      "https://graph.microsoft.com/v1.0/me?$select=surname,givenname,jobTitle",
      options
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }, []);

  const getSSOAccessToken = useCallback(async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...authRequest,
        account: accounts[0],
      });
      return response.accessToken;
    } catch (e) {
      console.log("Failed to silently fetch access token: " + e);
      try {
        const redirectResponse = await instance.acquireTokenRedirect({
          ...authRequest,
          account: accounts[0],
        });
      } catch (err) {
        throw new Error("Failed to fetch SSO Access token: " + err);
      }
    }
  }, [instance, accounts]);

  const fetchUserData = useCallback(
    async (graphAccessToken: string) => {
      try {
        const graphResponse = await callMsGraph(graphAccessToken);
        return {
          graphResponse,
        };
      } catch (e) {
        throw new Error("Failed to fetch User Data: " + e);
      }
    },
    [callMsGraph]
  );

  const requestProfileData = useCallback(() => {
    getSSOAccessToken()
      .then((graphAccessToken) => {
        if (!graphAccessToken) {
          return;
        }
        fetchUserData(graphAccessToken)
          .then((userData) => {
            setAuthInfo({
              graphAccessToken: graphAccessToken,
              firstName: userData.graphResponse.givenName,
              lastName: userData.graphResponse.surname,
            });
          })
          .catch((e) => {
            console.error("Error fetching User Data: " + e);
          });
      })
      .catch((e) => console.error(e));
  }, [fetchUserData, getSSOAccessToken]);

  const logout = () => {
    instance.logoutRedirect();
  };

  useEffect(() => {
    if (accounts.length > 0) {
      requestProfileData();
      console.debug("Authorizing User...");
    }
  }, [accounts, requestProfileData]);

  return (
    <AzureSSOContext.Provider value={{ logout: logout, authInfo: authInfo }}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        {authInfo === undefined && (
          <LoadingView message="Signing in with Active Directory..." />
        )}
        {authInfo !== undefined && props.children}
      </MsalAuthenticationTemplate>
    </AzureSSOContext.Provider>
  );
};

import {
  PublicClientApplication,
  LogLevel,
  RedirectRequest,
} from "@azure/msal-browser";

export const MsalConfig: PublicClientApplication = new PublicClientApplication({
  auth: {
    authority:
      "https://login.microsoftonline.com/" +
      process.env.REACT_APP_AZURE_TENANT_ID!,
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID!,
    redirectUri:
      window.location.protocol + "//" + window.location.host,
    postLogoutRedirectUri: window.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false,
    },
  },
});

export const loginRequest: RedirectRequest = {
  scopes: ["User.read"],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

import Send from "@mui/icons-material/Send"
import { CSSProperties } from "react";
import { CircularProgress } from "@mui/material";

type SubmitButtonProps = {
    onClick?: () => void;
    isWaiting?: boolean;
    className?: string;
};

const SubmitButton: React.FC<SubmitButtonProps> = (props) => {

    const buttonClicked = () => {
        if (props.onClick !== undefined && props.isWaiting !== true) {
            props.onClick()
        }
    };

    const iconStyle: CSSProperties = {
        position: "relative",
        top: "3px",
        alignContent: "center"
    };

    const progressAdjust: CSSProperties = {
        color: "white"
    };

    return (
        <div>
            <button className={props.className} onClick={buttonClicked}> {props.isWaiting === true ? <CircularProgress style={progressAdjust}/> : <Send style={iconStyle} /> }</button>
        </div>
    );
};

export default SubmitButton;
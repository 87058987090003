import { MsalConfig } from "../auth/AzureAuthConfig";
import { ChatResponse, ChatService } from "./ChatService";
import { ChatStatement, Role } from "./ChatStatement";
import { AccountInfo } from "@azure/msal-browser";
import { systemPrompt } from "./GPT";

export type APIResponse = {
  error?: {
    code: string;
    message: string;
  }
  id: string;
  object: string;
  created: number;
  choices: {
    index: number;
    message: ChatStatement;
    finishReason: string;
  }[];
  usage: {
    prompt_tokens: number;
    completion_tokens: number;
    total_tokens: number;
  };
};

class AzureOpenAiChatService implements ChatService {
  async fetchChatResponse(
    accounts: AccountInfo[],
    messages: ChatStatement[],
  ): Promise<ChatResponse> {
    const content = await azureOpenAiChatServiceRequest(accounts, messages);
    return { statement: { role: Role.Assistant, content } };
  }
}

async function azureOpenAiChatServiceRequest(
  accounts: AccountInfo[],
  messages: ChatStatement[],
) {
  const loginRequest = {
    account: accounts[0],
    scopes: ["https://cognitiveservices.azure.com/.default"],
  };

  let accessToken = null;

  await MsalConfig.acquireTokenSilent(loginRequest)
    .then(async (loginResponse) => {
      // login success
      accessToken = loginResponse.accessToken;
    })
    .catch((error) => {
      // login failure
      console.error({ error });
      MsalConfig.acquireTokenPopup(loginRequest)
        .then(async (loginResponse) => {
          accessToken = loginResponse.accessToken;
        })
        .catch((error) => {
          console.error({ error });
        });
    });

  if (!accessToken) throw new Error("Access Denied.");

  const response: APIResponse = await fetch(
    process.env.REACT_APP_COGNITIVE_AI_ENDPOINT!,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: JSON.stringify({ messages: [systemPrompt, ...messages] }),
    },
  ).then(async (response) => {
    return await response.json();
  });

  if (response.error) throw new Error(response.error.code + ": " + response.error.message);

  if (response.choices && response.choices.length > 0)
    return response.choices.pop()!.message.content.toString();
  throw new Error("Access Denied.");
}

export default AzureOpenAiChatService;

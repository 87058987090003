import { CircularProgress } from "@mui/material";
import styled from "styled-components";

type LoadingViewProps = {
  message?: string;
};

const Container = styled.div`
  align-items: center;
  text-align: center;
  padding: calc(100vw * 0.25);
`;
const MessageBox = styled.div`
  display: block;
`;

const LoadingView: React.FC<LoadingViewProps> = (props) => {
  let message = props.message;

  if (!message) {
    message = "Loading...";
  }
  return (
    <Container>
      <CircularProgress />
      <MessageBox>{message}</MessageBox>
    </Container>
  );
};

export default LoadingView;

export type ChatStatement = {
  role: Role;
  content: string;
};

export enum Role {
  Assistant = "assistant",
  System = "system",
  User = "user",
}

import React, { useEffect, useRef, useState } from 'react';
import { Role, ChatStatement } from './ChatStatement';
import './Chat.css';
import SubmitButton from "../ui/SubmitButton";
import AzureOpenAiChatService from './AzureOpenAiChatService';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useMsal } from '@azure/msal-react';

function Chat() {

  const { accounts } = useMsal();

  const chatService = new AzureOpenAiChatService();

    const [chatConversation, setChatConversation] = useState<ChatStatement[]>([]);
    const [inputMessage, setInputMessage] = useState<string>("");

    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    };
    const [, updateState] = React.useState({});

    const forceUpdate = React.useCallback(() => updateState({}), []);

    const addResponse = (response: ChatStatement) => {
        let state = chatConversation;
        state.push(response);
        setChatConversation(state);
        forceUpdate();
        setTimeout(() => {
            scrollToBottom();
        }, 100);
    };


    const submitButonClicked = () => {
        if (inputMessage === "") {
            return;
        }

        let state = chatConversation;
        state.push({ role: Role.User, content: inputMessage });
        setInputMessage("");
        setChatConversation(state);
        chatService.fetchChatResponse(accounts, chatConversation).then(
            (response) => {
                addResponse(response.statement);
                setTimeout(() => {
                    scrollToBottom()
                }, 100)
            }
        ).catch(
            (error) => {
                alert(error);
            }
        );

    };

    const isWaitingForResponse = () => {
        if (chatConversation.length === 0) {
            return false;
        }
        return chatConversation[chatConversation.length - 1].role === Role.User;
    };

    const submitOnEnter = (e: any) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            submitButonClicked();
        }
    }

    const getCssClassForStatement = (statement: ChatStatement) => {
        return statement.role === Role.User ? "chat-statement-user" : "chat-statement-bot";
    };

    useEffect(() => {
        // scrollToBottom(); // this was not working as expected on mobile devices. The window kept scrolling on each keystroke.
    }, [chatConversation, inputMessage]);

    let messageIndex = 0;
    const generateMessageIndex = () => {
        return messageIndex++;
    };

    return (
        <div className="chat-container">
            <div className="chat-conversation">
                <div className="chat-header">
                    Azure OpenAI Chat
                </div>
                {chatConversation.map(statement => (
                    <div key={generateMessageIndex()} className={getCssClassForStatement(statement)}>
                      <ReactMarkdown>{statement.content}</ReactMarkdown>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            <div className="chat-input-container">
                <textarea className="chat-input" onKeyDown={submitOnEnter} onChange={e => setInputMessage(e.target.value)} value={inputMessage} />
                <SubmitButton className="chat-submit" isWaiting={isWaitingForResponse()} onClick={submitButonClicked} />
            </div>
        </div>
    );
}

export default Chat;
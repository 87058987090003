import './App.css';
import Config from './Config';
import { AppRoutes } from './routes/AppRoutes';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          {Config.appName}
        </p>
      </header>
      <div className="App-content">
        <AppRoutes />
      </div>
      <footer className="App-footer">
        <p>
          version {Config.appVersion} ({Config.gitSha})
        </p>
      </footer>
    </div>
  );
}

export default App;

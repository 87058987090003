import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Chat from "../chat/Chat";

const Loading = () => <p>Loading ...</p>;

export const AppRoutes: React.FC = () => {
    return (
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/openai-chat" element={<Chat />} />
          <Route path="*" element={<Navigate to="/openai-chat" />} />
        </Routes>
      </React.Suspense>
    );
  };
  
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from './ErrorBoundry';
import { MsalProvider } from '@azure/msal-react';
import { MsalConfig } from './auth/AzureAuthConfig';
import { AzureSSOProvider } from './auth/AzureSSOProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <MsalProvider instance={MsalConfig}>
        <AzureSSOProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AzureSSOProvider>
      </MsalProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
